import { useRef, useEffect } from 'react';
import QRCodeStyling from 'qr-code-styling';
import logo from './logo-hg.svg';
import './App.css';

function App() {
  const ref = useRef(null);

  const { search } = window.location;
  const params = new URLSearchParams(search);
  const accessId = params.get('accessId');
  const width = params.get('width');
  const valid = params.get('valid');
  // const valid = '1';

  const qrCode = new QRCodeStyling({
    type: "svg",
    width: width - 80,
    height: width - 80,
    margin: 20,
    image: logo,
    dotsOptions: {
      color: valid === '1' ? "#203C4D" : "#EBEEF2",
      type: "extra-rounded"
    },
    imageOptions: {
      crossOrigin: "anonymous",
      margin: 10
    },
    cornersSquareOptions: {
      color: valid === '1' ? "#203C4D" : "#EBEEF2",
      type: "rounded"
    },
    cornersDotOptions: {
      color: valid === '1' ? "#087ccd"  : "#EBEEF2",
      type: "rounded"
    },
    backgroundOptions: {
      color: '#FFF',
    }
  });

  const convertSvgToPng = (send) => {
    const svg = document.querySelector('svg');
    const svgString = new XMLSerializer().serializeToString(svg);

    const format = 'png';
    const svgData = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString)));
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = 720;
    canvas.height = 720;

    const image = new Image();
    image.onload = function () {

      context.clearRect(0, 0, 720, 720);
      context.drawImage(image, 0, 0, 720, 720);
      const pngData = canvas.toDataURL('image/' + format);
      if (send) {
        const message = {
          data: pngData,
          type: 'DOWNLOAD_QR'
        }
        window.ReactNativeWebView.postMessage(JSON.stringify(message));
      }
    };
    image.src = svgData;
  }

  const download = () => {
    // qrCode.download();
    convertSvgToPng(false)
    setTimeout(() => {
      convertSvgToPng(true);
    }, 500)
  }

  useEffect(() => {
    qrCode.append(document.getElementById("qrContainer"));
  }, []);

  useEffect(() => {
    qrCode.update({
      data: accessId
    });
  }, [accessId]);

  return (
    <div className="App">
      <div id="qrContainer" ref={ref} />
      <canvas style={{ display: 'none' }} id="canvas"></canvas>
      <button style={{ display: 'none' }} id="downloadBtn" onClick={() => download()} />
    </div>
  );
}

export default App;
