import { v4 as uuidv4 } from 'uuid';

let promiseChain = Promise.resolve();
const callbacks = {};
const webViewBridge = () => {
  window.webViewBridge = {
    /**
     * send message to the React-Native WebView, Android Native and iOS Native onMessage handler
     * @param data - data to pass
     * @param type - message code type so RN knows how to handle request
     * @param success - success callback
     * @param error - error callback
     */
    send(data, type, success, error) {
      const msgObj = { type, data: data || {} };

      if (success || error) {
        msgObj.msgId = uuidv4();
      }

      const msg = JSON.stringify(msgObj);

      promiseChain = promiseChain.then(() => new Promise(((resolve) => {
        if (msgObj.msgId) {
          callbacks[msgObj.msgId] = {
            success,
            error,
          };
        }

        if (window.ReactNativeWebView) { window.ReactNativeWebView.postMessage(msg); }
        if (window.AndroidInterface) { window.AndroidInterface.onMessage(msg); }
        if (window.webkit) { window.webkit.messageHandlers.AppleInterface.postMessage(msg); }

        resolve();
      }))).catch((e) => {
        console.error(`rnBridge send failed ${e.message}`);
      });
    },
  };

  window.addEventListener('message', (e) => {
    let message;
    try {
      message = JSON.parse(e.data);
    } catch (err) {
      console.error(`failed to parse message from react-native ${err}`);
      return;
    }

    if (message.response && callbacks[message.msgId]) {
      if (message.success) {
        callbacks[message.msgId].success.apply(null, message.response);
      } else {
        callbacks[message.msgId].error.apply(null, message.response);
      }
      delete callbacks[message.msgId];
    }
  });
};

export default webViewBridge;
